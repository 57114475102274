@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0rem;
  padding: 0rem;
}

body {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* background-image: linear-gradient(rgba(12, 12, 9, 0.343), rgba(0, 0, 0, 0.5)),
    url('images/reviewBackground.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
}

/* @media only screen and (max-width: 1440px) {
  body {
    background-size: cover;
  }
} */

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@keyframes shrink {
  0% {
    background-size: 120% 120%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 768px) {
  @keyframes shrink {
    0% {
      background-size: 140vh 120vh;
    }
    100% {
      background-size: 120vh 100vh;
    }
  }
}

.map-container {
  height: 100%;
  width: 100%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: fit-content;
  height: fit-content;
  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.img {
  max-height: 80vh;
}
